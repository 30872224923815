<template>
    <b-overlay :show="pageBusy" variant="transparent">
        <b-card>
            <div class="row pt-0">
                <div class="col-12 pb-1">
                    <span class="font-weight-bolder">Filters</span>
                </div>
                <div class="col-lg-3 col-12 col-md-6">
                    <label>Date Range</label>
                    <flat-pickr
                        v-model="range"
                        class="form-control"
                        :config="{ mode: 'range'}"
                        />
                </div>
                <div class="col-lg-3 col-12 col-md-6">
                    <label>Status</label>
                    <v-select  v-model="mode"  :options="modeOptions" :reduce="e=> e.value" :clearable="false"></v-select>
                </div>
                <div class="col-lg-3 col-12 col-md-6">
                    <label>Company ID</label>
                    <input v-model="searchId" type="text" class="form-control" placeholder="">
                </div>
                <div class="col-lg-3 col-12 col-md-6 pt-2 pt-lg-2" @click="filterCompany()">
                    <span class="btn btn-primary"> <feather-icon icon="SearchIcon" /> Search</span>
                </div>
            </div>
        </b-card>
        <b-card>
            <b-overlay :show="itemBusy">
                <b-table
                    class="rounded border"
                    :items="items"
                    :fields="fields"
                    :per-page="10"
                    :current-page="1"
                    responsive
                >

                    <template #head()="scope">
                        <div class="text-nowrap text-primary">
                        {{ scope.label }}
                        </div>
                    </template>
                    <template #cell(type)="data">
                        <span class="text-nowrap badge badge-primary" v-if="data.item.id">
                           {{getCatType(data.item.companyType)}}
                        </span>
                    </template>
                    <template #cell(name)="data">
                        <span class="three-line-ellipsis" v-if="data.item.id && data.item.company">
                           {{data.item.company.name}}
                           <small class="d-block text-primary">{{ data.item.companyId }}</small>
                        </span>
                        <div v-else class="pt-2">
                        
                        </div>
                    </template>
                    <template #cell(status)="data">
                        <span class="text-nowrap" v-if="data.item.id">
                            <b-badge :variant="displayStatus[1][data.value]">
                                {{ displayStatus[0][data.value] }}
                            </b-badge>
                        </span>
                    </template>
                    <template #cell()="data">
                        <div class="text-nowrap pt-1">
                            {{data.value}}
                        </div>
                    </template>
                    <template #cell(visibility)="data">
                        <div class="text-nowrap pt-1 small" v-if="data.item.id">
                            <span class="text-success" v-if="data.item.isVisible">
                                Visible
                            </span>
                            <span v-else class="text-danger">
                                Hidden
                            </span>
                        </div>
                    </template>
                    <template #cell(createdAt)="data">
                        <div class="text-nowrap pt-1" v-if="data.item.id">
                            {{ dayjs(data.value).format("MMM D, YYYY h:mm A") }}
                        </div>
                    </template>
                    <template #cell(updatedAt)="data">
                        <div class="text-nowrap pt-1" v-if="data.item.id">
                            {{ dayjs(data.value).format("MMM D, YYYY h:mm A") }}
                        </div>
                    </template>
                    <template #cell(actions)="data">
                        <!-- <div class="text-nowrap" v-if="data.item.id">
                            <b-button size="sm" pill variant="outline-primary" v-b-toggle.validate @click="triggerPreview(data.item)">
                        Review
                            </b-button>
                        </div> -->

                        <b-dropdown
                            size="lg"
                            variant="link"
                            toggle-class="text-decoration-none"
                            no-caret
                            v-if="data.item.id"
                        >
                            <template #button-content>
                                <feather-icon
                                    icon="MoreVerticalIcon"
                                ></feather-icon>
                            </template>
                            <b-dropdown-item @click="toggleStatus(data.item)">
                                <feather-icon icon="SettingsIcon"  />
                                <span class="align-middle ml-50">Toggle status</span>
                            </b-dropdown-item>
                            <b-dropdown-item @click="triggerSetReqQuestions(data.item)">
                                <feather-icon icon="EyeIcon"  />
                                <span class="align-middle ml-50">See response</span>
                            </b-dropdown-item>
                        </b-dropdown>
                    </template>
                    <template #cell(image)="data">
                        <span class="text-nowrap" v-if="data.item.id">
                            <b-img :src="data.value" style="height: 50px" rounded class="cursor-pointer" @click="triggerViewImage([data.value])">

                            </b-img>
                        </span>
                    </template>
                </b-table>
            </b-overlay>
            <b-modal id="view-images" ok-only hide-header size="lg" centered no-close-on-backdrop no-close-on-esc body-bg-variant="dark">
                <div style="min-height: 400px;">
                    <b-carousel id="carousel-main" style="text-shadow: 0px 0px 2px #000" :interval="7000" :controls="images.length > 1" :indicators="images.length>1" img-height="480px">
                        <b-carousel-slide class="display-image-holder" v-for="(item, index) in images" :key="index" :text="`${item}`" :img-src="item" />
                    </b-carousel>
                </div>
            </b-modal>
            <b-modal id="view-company" ok-only title="View Company Detail" size="xl" centered no-close-on-backdrop no-close-on-esc>
               <div class="row">
                   <div class="col-12" style="min-height: 600px;">
                       <iframe class="w-100" height="100%" :src="`${link}/preview-company/data-only/company?id=${account.id}&mode=${mode.value}`" frameborder="0"></iframe>
                       <!-- <company :company="account" /> -->
                   </div>
               </div>
            </b-modal>
            <div class="d-flex p-1">
                <div class="w-100">
                    Page <span class="text-primary"> {{currentPage}}</span> of <span class="text-primary">{{Math.ceil(totalRows/10)}}</span>
                </div>
                <div class="w-100">
                    <b-pagination
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="10"
                        aria-controls="users-table"
                        align="right"
                        size="sm"
                        class="my-0"
                        @page-click="loadNewPage"
                    />
                </div>
            </div>
        </b-card>
        <b-modal id="request-modal-trigger" centered size="lg" no-close-on-backdrop no-close-on-esc title="See request data" ok-only>
            <div class="mt-2" v-if="request.company">
                <h3>{{ request.company.name }}</h3>
            </div>
            <div style="height: 400px !important; overflow-y: auto;" class="pr-1">
                <div class="d-flex align-items-center mt-1" v-for="(item, i) in request.response" :key="i">
                    <div class="w-50">
                        {{ item.key }}
                    </div>
                    <div class="w-100 font-weight-bolder">
                        {{ item.value }}
                    </div>
                </div>
                <div class="d-flex align-items-center mt-1">
                    <div class="w-50">
                        Status
                    </div>
                    <div class="w-100 font-weight-bolder">
                        <b-badge :variant="displayStatus[1][request.status]">
                            {{ displayStatus[0][request.status] }}
                        </b-badge>
                    </div>
                </div>
                <div class="d-flex align-items-center mt-1">
                    <div class="w-50">
                        Created at
                    </div>
                    <div class="w-100 font-weight-bolder">
                        {{ request.createdAt ? dayjs(request.createdAt).format("MMM D, YYYY h:mm A") : '-----------------' }}
                    </div>
                </div>
            </div>
        </b-modal>
    </b-overlay>

</template>

<script>

import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import dayjs from "dayjs";
import flatPickr from 'vue-flatpickr-component'
import Utils from '@/utils/index';
import Vue from "vue";
import commonApi from '@/services/api';
import API from './api'
// import Company from './SingleCompany.vue'

const vm = new Vue({});
const toast = useToast()
export default {
    name: 'Feedback',
    components: {
        flatPickr,
        vSelect,
        // Company
    },
    data() {
        return {
            reviewMode: 1,
            currency: Utils.formatCurrency,
            campaignTypes:{
                '1': 'Recursive',
                '2': 'Normal',
                '3': 'Ends on target attained',
                '5': 'Never ends',
                // rejected: -10,
            },
            request: {},
            link: 'https://www.amrefinance.com',
            ftoken: '',
            dayjs: dayjs,
            currentPage: 1,
            totalRows: 10,
            range: '',
            reviews: [],
            searchDate: '',
            dateRange: '',
            searchId: '',
            status: '',
            durations: [
                {title: 'Hourly', value: 5 },
                {title: 'Daily', value: 6 },
                {title: 'Weekly', value: 7 },
                {title: 'Monthly', value: 8 },
                {title: 'Yearly', value: 9 },
                {title: 'One Time', value: 11 },
                {title: 'Life Time', value: 10 },
            ],
            displayStatus:[
                {
                    '1':'Pending', 
                    '5':'Processed', 
                    '-1':'Rejected', 
                    '-5':'Permanently rejected', 
                    'undefined': 'System Status'
                },
                {
                    '1':'dark', 
                    '5': 'success', 
                    '-1': 'danger', 
                    '-5': 'danger', 
                    'undefined': 'success'
                },
            ],
            statusOptions: [
                {label:'Pending',"value":1},
                {label:'Approved',"value":5},
                {label:'Rejected',"value":-1},
                {label:'Permanently Rejected',"value":-5},
            ],
            modeOptions: [
                {label:'All',"value":''},
                {label:'Pending',"value":1},
                {label:'Processed',"value": 5},
            ],
            mode: '',
            user: {
                text:'',
                status: '',
                id: ''
            },
            account: {
                reviewHistory: []
            },
            checked: '',
            images: [],
            frMotifs: {
                '1': 'Inappropriate images',
                '2': 'Images inappropriées',
                '3': 'Compte non vérifié',
                '4': 'Informations incomplètes',
            },
            reviewOptions: [
                {label:'Invalid information',"value":1},
                {label:'Inappropriate images',"value": 2},
                {label:'Account not verified',"value": 3},
                {label:'Incomplete information',"value": 4},
                ],
            items: [],
            fields: [
                {key: 'actions', label: 'Actions'},
                {key: 'name', label: 'Company'},
                {key: 'status', label: 'Status'},
                {key: 'createdAt', label: 'Created At'},
                {key: 'updatedAt', label: 'Updated At'},
            ],
            categories: [],
            searchParams: {},
            itemBusy: false,
            pageBusy: false,
            showSidebar: false,
            isEdit: false,
            imageUploadTarget: 1,
            company: {
                logo: '',
                backgroundImage: '',
            },
            showAddCompanySidebar: false,
            retriedCategories: 0,
            applicationStatus: 0,
        }
    },
    watch: {
        range(newRange, oldRange) {
            newRange = newRange.split(' to ');
            if(newRange.length === 2) {
                this.searchDate = {
                    from: newRange[0],
                    to: newRange[1]
                }
            }else if(newRange[0].length == 10) {
                 this.searchDate = {
                    from: newRange[0],
                    to: newRange[0]
                }
            }else {
                this.searchDate = '';
            }
        },
        applicationStatus(data) {
            // if(data == 1) {
            //     // this.user.status = {
            //     //     value: 3,
            //     //     label: 'Run',
            //     // }
            //     // this.user.text = '';
            // }else{
            //     this.user.status = '';
            // }
        },
        mode(data){
            // this.setStates();
            this.filterCompany();
        }
    },
    created() {
        this.items = Utils.completeTable();
        // this.getRequests();
    },
    mounted() {
        this.getRequests();
    },
    methods: {
        saveRequest(e){
            e.preventDefault();
            if(!this.request.buttonText){
                this.showToast('Please enter button text for English (required)');
                return;
            }
            for(let i = 0; i < this.request.questions.length; i++){
                if(!this.request.questions[i].title){
                    document.getElementById(`questions-${i}`).focus();
                    this.showToast('Please enter questions for all opened boxes')
                    return;
                }
            }

            const params = {
                buttonTextFr: this.request.buttonTextFr || this.request.buttonText,
                buttonText: this.request.buttonText,
                companyId: this.request.companyId,
                questions: this.request.questions
            }
            
            API.submitQuestion(params).then(res=>{
                if(res.success && res.data) {
                    this.resetCompany();
                    setTimeout(()=>{
                        this.getRequests(1);
                    },200);
                    this.showToast('Questions added successfully','CheckCircle','success');
                    this.$bvModal.hide('request-modal-trigger');
                }
            }).catch(err=>{
                console.log(err);
            })

        },
        removeQuestion(index){
            this.request.questions = this.request.questions.filter((e,r)=> r != index );
        },
        addQuestion(){
            this.request.questions.push({title: '', isRequired: false});
            this.request = {...this.request};
        },
        triggerSetReqQuestions(request){
            this.request = JSON.parse(JSON.stringify(request));
            //this.request.questions = [];
            this.$bvModal.show('request-modal-trigger')
        },
        triggerAddCompany(){
            this.resetCompany();
            this.showAddCompanySidebar = true;
        },
        toggleStatus(item) {
            const status = item.status == 1 ? 'Pending' : 'Processed';
            const targetStatus = item.status != 1 ? 'Pending' : 'Processed';
            this.$bvModal.msgBoxConfirm(`Are you sure you want to toggle status from "${status}" to "${targetStatus}"`, {
            title: 'Toggle',
            size: 'md',
            buttonSize: 'sm',
            okVariant: 'danger',
            headerClass: 'p-2 border-bottom-0',
            bodyClass: 'text-break',
            footerClass: 'p-2 border-top-0',
            cancelTitle: 'Cancel',
            centered: true
            })
            .then(value => {
                if(value){
                    this.toggleRequestStatus(item.requestId);
                }
            })
            .catch(err => {
                // An error occurred
            })
        },
        triggerToggleQuestions(id) {
            this.$bvModal.msgBoxConfirm("Are you sure you want to toggle questions visibility?", {
            title: 'Toggle questions visibility',
            size: 'md',
            buttonSize: 'sm',
            okVariant: 'danger',
            headerClass: 'p-2 border-bottom-0',
            bodyClass: 'text-break',
            footerClass: 'p-2 border-top-0',
            cancelTitle: 'Cancel',
            centered: true
            })
            .then(value => {
                if(value){
                    this.toggleQuestionsVisibility(id);
                }
            })
            .catch(err => {
                // An error occurred
            })
        },
        toggleQuestionsVisibility(id) {
          API.toggleQuestionsVisibility(id).then(res => {
            this.pageBusy = false;
            if(res.success) {
              this.items = this.items.map(e=>{
                if(e.companyId == res.data.companyId){
                    e = res.data;
                }
                return e
              })
                // this.getCategories(this.currentPage);
                this.showToast('Questions Visibility toggled successfully', 'CheckIcon', 'success');
            }
          }).catch(() => {
              this.pageBusy = false;
              this.showToast('Failed to toggle questions visibility');
          })
        },
        toggleRequestStatus(id) {
          API.toggleRequestStatus(id).then(res => {
            this.pageBusy = false;
            if(res.success) {
              this.items = this.items.map(e=>{
                if(e.requestId == id){
                    delete res.data.company;
                    e = {...e, ...res.data};
                }
                return e
              })
                // this.getCategories(this.currentPage);
                this.showToast('Status toggled successfully', 'CheckIcon', 'success');
            }
          }).catch(() => {
              this.pageBusy = false;
              this.showToast('Failed to toggle status');
          })
        },
        removeImage(target = 1){
            if(target == 1){
                this.company.logo = '';
            }else{
                this.company.backgroundImage = '';
            }
        },
        triggerImageUpload(source = 1){ // 1 for logo and 2 for background image
            this.imageUploadTarget = source;
            let uploader = document.getElementById('image-uploader');
            if(!uploader){
                uploader = document.createElement('input');
                uploader.setAttribute('type', 'file');
                uploader.setAttribute('id', 'image-uploader');
                uploader.setAttribute('accept', '.jpg, .png, .webp');
                uploader.setAttribute('multipart', false);
                uploader.onchange = e=>{
                    this.uploadImage(uploader.files[0]);
                }
            }
            uploader.click();
        }, 
        uploadImage(image){ 
            commonApi.imageUpload(image).then(res=>{
                if(res.success){
                    console.log(this.imageUploadTarget, res.data)
                    if(this.imageUploadTarget == 1){
                        this.company.logo = res.data[0]
                    }else{
                        this.company.backgroundImage = res.data[0]
                    }
                }
            }).catch(err=>{
                console.log(err)
            })
        },
        getDuration(e){
            const duration = this.durations.find(d => d.value == e);
            if(duration){
                return duration.title
            }
            return "";
        },
        getCatType(id){
            const categories = this.categories.find(c=> c.typeId == id);
            if(categories) return categories.name;
            return '';
        },
        getCategories() {
            // this.pageBusy = true;
            if(this.retriedCategories > 10){
                return;
            }
            API.getCategories({ pageSize: 100}, false, true).then(res => {
                if(res.success && res.data) {
                    this.retriedCategories = 0;
                    this.categories = res.data.list;
                }
            }).catch(() => {
                // this.retryGetCategoriesId();
            })
        },
        setStates(){
            if(this.mode.value == 'draft'){
                this.statusOptions = [
                    // {label:'Not submitted',"value":1},
                    {label:'Pending',"value":1},
                    {label:'Approved',"value":5},
                    // {label:'Initialized',"value":20},
                    {label:'Rejected',"value":-1},
                    {label:'Permanently Rejected',"value":-1},
                ]
            }else{
                this.statusOptions = [
                    {label:'Approved',"value":100},
                    {label:'Running',"value":300}, // running
                    {label:'Paused',"value":400},
                    {label:'Stopped',"value":-300},
                    {label:'Pending Deletion',"value":-500},
                ]
            }
            this.status = '';
        },
        showCompany(data = {}) {
            this.account = data;
            this.$bvModal.show('view-company');
        },
        triggerViewImage(image = []) {
            this.images = image;
            this.$bvModal.show('view-images');
        },
        deleteCompany(e) {
            API.deleteCompany({id: this.account.id}).then(res=>{
                if(res.success) {
                    this.user.status = {};
                    this.user.text = '';
                    setTimeout(()=>{
                        this.getRequests(this.currentPage);
                    },200);
                    this.showSidebar = false;
                    this.showToast('Company deleted successfully','CheckCircle','success');
                }
            }).catch(err=>{
                console.log(err);
            })
        },
        submitCompany(e) {
            let request = API.addCompany;
            if(this.company.id){
                request = API.editCompany
            }
            request(this.company).then(res=>{
                if(res.success && res.data) {
                    this.resetCompany();
                    setTimeout(()=>{
                        this.getRequests(1);
                    },200);
                    e();
                    this.showToast('Company Added successfully','CheckCircle','success');
                }
            }).catch(err=>{
                console.log(err);
            })
        },
        resetCompany(){
            this.request = {
                questions: [],
            }
            this.company = {
                logo: '',
                backgroundImage: '',
            }
        },
        triggerPreview(data) {
            this.company = data;
            this.showAddCompanySidebar = true;
        },
        loadNewPage(e,page) {
            console.log(page)
            e.preventDefault();
            this.getRequests(page);
        },
        getRequests(page = 1) {
            // this.pageBusy = true;
            API.getRequests({...this.searchParams, page, mode: this.mode.value || 'submitted'}).then(res => {
                this.pageBusy = false;
                if(res.success && res.data) {
                    this.currentPage = res.data.currentPage;
                    this.items = Utils.completeTable(res.data.list);
                    this.totalRows = res.data.total || 10;
                }
            }).catch(() => {
                this.pageBusy = false;
                this.showToast();
            })
        },
         filterCompany() {
            
            this.searchParams = {};
            if(this.searchId) {
                this.searchParams.companyId = this.searchId;
            }
            if(this.mode) {
                this.searchParams.status = this.mode;
            }
            if(this.searchDate && this.searchDate.from) {
                this.searchParams.startDate = this.searchDate.from + ' 00:00:00';
                if(!this.searchDate.to) {
                    this.this.searchDate.to = this.searchDate.from
                }
                this.searchParams.endDate = this.searchDate.to + ' 23:59:59';
            }
            
            this.getRequests(1);
            this.currentPage = 1;
        },
        showToast(message, icon, variant) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: message || 'Error',
                    icon: icon || 'InfoIcon',
                    variant: variant || 'danger',
                },
            })
        },
        showModal(feedback) {
            this.$bvModal.msgBoxOk(feedback, {
            title: 'Feedback',
            size: 'md',
            buttonSize: 'md',
            okVariant: 'primary',
            headerClass: 'p-2 border-bottom-0',
            bodyClass: 'text-break',
            footerClass: 'p-2 border-top-0',
            centered: true
            })
            .then(value => {
                this.feedback = value
            })
            .catch(err => {
                // An error occurred
            })
        },
        markAsProcessed(feedbackId) {
            if(feedbackId) {
                this.feedbacks = {};
                // this.pageBusy = true;
                API.markAsProcessed({_id: feedbackId}).then(res => {
                    this.pageBusy = false;
                    if(res.success) {
                        this.items = this.items.map(item =>{
                            if(item.id == feedbackId) {
                                item.status = res.data.status;
                            }
                            return item;
                        })
                        this.showToast('Feedback Marked as processed', 'CheckIcon', 'success');
                    }
                }).catch(() => {
                    this.pageBusy = false;
                    this.showToast('Error processing feedback')
                })
            }
        }
    }
}
</script>

<style lang="scss" scope>

    @import '@core/scss/vue/libs/vue-select.scss';
    @import '@core/scss/vue/libs/vue-flatpicker.scss';

    .feedback-content{

        max-width: 100px !important;
    }
    .feedback-content:hover{
        transform: scale(1.2);
    }
    #carousel-main{
        text-align: center !important;
        img{
            height: 480px !important;
            width: auto !important;
            margin: auto
        }
    }
</style>